import React, { useContext } from "react"
import Layout from "../../components/layout"

import styles from "./index.module.scss"
import ThemeContext from "../../context/ThemeContext"
import t from '../../services/GetTranslationValue'
import { graphql } from "gatsby"
import GenericLandingContentWrapper from "../../components/text-helpers/generic-landing-content-wrapper"
import SEO from "../../components/seo"
import CasinoHelper from "../../services/casino-bonus-helpers"
import GameProvidersCards from "../../components/game-providers-cards/game-providers-cards"
import { GetCasinoBonusPairingsByProvider } from "../../services/game-provider-info"
import localize from "../../components/localize"

const Content = ({ gameProviders, translations, locale, bonus }) => {
  return (
    <GameProvidersCards iterable={gameProviders} iterableName={"gameProviders"} translationName={"gameProvider"} translations={translations} locale={locale} />
  )
}

const CasinoGameProvider = ({ data, pageContext, location }) => {
  const { locale, alternateLocaleSlugs } = pageContext

  let { translations } = useContext(ThemeContext)

  let pageData = data.sanityGenericLandingPage
  if (!pageData) pageData = {}

  let { edges } = data.allSanityGameProvider
  let nodes = edges.map(x => x.node).sort((a, b) => {
      if (b.name < a.name)
        return 1;
      if (b.name > a.name)
        return -1;

      return 0;
    });

  const site = useContext(ThemeContext)
  const bonus = site.bonuses;

  const casinosInfoData = (casinos, item) => {
    const bonusScorePairings = GetCasinoBonusPairingsByProvider(casinos, item)

    const casinosCount = bonusScorePairings.length;

    const maxBonusActive = bonusScorePairings.length > 0 && CasinoHelper.GetTopFirstDepositBonus(bonusScorePairings[0].casino);
    const maxBonus = getMaxBonusValue(maxBonusActive);

    return { item, casinosCount, maxBonus };
  }

  const getMaxBonusValue = (maxBonusActive) => {
    if (!maxBonusActive)
      return `-% / -${t.get(translations, 'currency')}`
    if (maxBonusActive.bonusValue)
      return `${maxBonusActive.bonusValue}% / ${maxBonusActive.maximumBonusAmount}${t.get(translations, 'currency')} `
    else {
      return `-% / -${t.get(translations, 'currency')}`
    }
  }

  let usedData = nodes.map(x => casinosInfoData(bonus, x.name ));

  return (
    <Layout silo={data.sanitySilo} pageContext={pageContext} location={location}>
      <SEO location={location}
        title={pageData && pageData.seoTitle}
        description={pageData && pageData.seoMeta}
        alternateSlug={alternateLocaleSlugs}
      >
        <script async defer type="application/ld+json">
          {getDataset(pageData, locale, location ,translations, usedData)}
        </script>
      </SEO>
      <GenericLandingContentWrapper 
        pageData={pageData} isVerticalTab={true} 
        bodyClassName={`gambleGeneralWrapper`} 
        headerClassName={`gambleGeneralWrapper ${styles.gameProvidersPageHeader}`}
      >
        <Content
          translations={translations}
          locale={locale}
          bonus={bonus}
          gameProviders={nodes}
        />
      </GenericLandingContentWrapper>
    </Layout>
  )
}

const getDataset = (pageData, locale, location, translations,
                    usedData ) =>  `{
	"@context": "https://schema.org/",
	"@type": "Dataset",
	"name": "${pageData.seoTitle}",
	"description": "${pageData.seoMeta}",
	"license": "https://mr-gamble.com/${locale}/${t.get(translations, 'licence-url')}",
	"url": "https://mr-gamble.com${location && location.pathname}",
	"inLanguage": "${locale}",
	"version": "2019",
	"creator": {
		"@type": "Organization",
		"url": "https://mr-gamble.com/",
		"name": "Mr Gamble"
	},
	"mainEntity": {
		"@type": "csvw:Table",
		"csvw:tableSchema": {
			"csvw:columns": [
				{
					"csvw:name": "${t.get(translations, 'gameprovider-name-schema')}",
					"csvw:datatype": "string",
					"csvw:ordered": true,
					"csvw:cells": [
						${usedData.map(x => `
            {
							"csvw:value": "${x.item.name}",
							"csvw:primaryKey": "${x.item.name}",
							"csvw:ordered": true
						}
						`)}
					]
				},
				{
					"csvw:name": "${t.get(translations, 'gameprovider-casinocount-schema')}",
					"csvw:datatype": "number",
					"csvw:ordered": true,
					"csvw:cells": [
						${usedData.map(x => `
            {
							"csvw:value": "${x.casinosCount}",
							"csvw:primaryKey": "${x.casinosCount}",
							"csvw:ordered": true
						}
						`)}
					]
				},{
					"csvw:name": "${t.get(translations, 'gameprovider-bonus-schema')}",
					"csvw:datatype": "string",
					"csvw:ordered": true,
					"csvw:cells": [
						${usedData.map(x => `{
							"csvw:value": "${x.maxBonus}",
							"csvw:primaryKey": "${x.maxBonus}",
							"csvw:ordered": true
						}`)}
					]
				}
      ]
		}
	}
}`

export default localize(CasinoGameProvider)
export const query = graphql`
  query GameProviderQuery {
    sanityGenericLandingPage(
      name: { eq: "Game Provider Landing" }
    ) {
      ...GenericLanding
    }
      sanitySilo (_id: {eq: "2a6640d6-d7c0-4fa1-915b-8dbdfc7b0570"}){
          _id
          breadcrumbTrail {
              ...LocaleSiloTrails
          }
      }
    allSanityGameProvider {
      edges {
        node {
          name
          largeIcon {
            asset {
                fluid {
                    ...GatsbySanityImageFluid
                }
            }
          }
          _updatedAt
        }
      }
    }
  }
`
